import React from 'react';

const Footer = () => (
  <footer className="w-full bg-[#0E0E11] text-[#848484] pt-12 pb-8 text-[13px] leading-relaxed">
    <div className="max-w-7xl mx-auto px-4 relative">
      <div className="absolute left-0 right-0 top-0 h-[0.4px] bg-[#848484] opacity-50"></div>
      <div className="pt-10">
        <p className="mb-4">Copyright © Basicfive Inc. All rights reserved.</p>
        <p className="mb-[0.1rem]">
          (주) 베이직파이브 대표이사 전휘찬 <span className="mx-2 text-[#848484] opacity-50">|</span> 주소 : 서울특별시 마포구 마포대로 122 18층 <span className="mx-2 text-[#848484] opacity-50">|</span> 사업자등록번호 : 787-86-03205
        </p>
        <p className="mb-4">
          통신판매업신고번호 : 제 2024-서울마포-1595 <span className="mx-2 text-[#848484] opacity-50">|</span> 이메일문의 : gnlcks82@gmail.com
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;