import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationBar = () => {
  const location = useLocation();
  const navItems = [
    { name: '서비스 소개', path: '/' },
    { name: '생성된 이미지', path: '/generated-images' },
  ];

  const isActive = (path) => {
    if (location.pathname === '/' && path === '/service') {
      return true;
    }
    return location.pathname === path;
  };

  return (
    <nav className="fixed left-0 top-0 h-full w-64 bg-[#0E0E11] p-6 flex flex-col">
      <div className="mb-10 pl-4 pt-6">
        <h1 className="text-[#EAEAEA] text-3xl font-bold">에헤모</h1>
        <p className="text-[#878787] text-sm">사진퀄리티 AI 헤어 모델</p>
      </div>
      
      <ul className="flex-grow py-6">
        {navItems.map((item) => (
          <li key={item.name} className="mb-[0.1rem]">
            <Link
              to={item.path}
              className={`block py-3 px-8 rounded-xl font-bold ${
                isActive(item.path)
                  ? 'bg-[#1D1D1D] text-white'
                  : 'text-[#878787]'
              }`}
            >
              {item.name}
            </Link>
          </li>
        ))}
        <li className="mb-[0.1rem]">
          <a
            href="https://ehemo.basicfive.co.kr/"
            target="_blank"
            rel="noreferrer"
            className={`block py-3 px-8 rounded-xl font-bold text-[#878787]`}
          >
            앱 설치하기
          </a>
        </li>
      </ul>
    </nav>
  );  
};

export default NavigationBar;