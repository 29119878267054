import React from 'react';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import HairStyleCarousel from './FourthSection';
import Footer from './Footer';

const MainContent = () => {
  // 이미지 URL들을 배열로 저장 (실제 URL로 교체 필요)

  return (
    <div>
      <FirstSection />
      <div className="h-24 bg-[#0E0E11] w-full" /> {/* 첫 번째와 두 번째 Section 사이의 공백 */}
      <SecondSection />
      <ThirdSection />
      <HairStyleCarousel />
      <Footer/>
    </div>
  )
};

export default MainContent;