import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const ThirdSection = () => {
  const image_realistic= 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_2.JPEG';

  return (
    <div className="flex items-center justify-center bg-[#0E0E11] text-white py-16 px-8 w-full">
      <div className="flex items-center justify-between w-full max-w-7xl mx-auto">
        {/* Text Section (now on the left) */}
        <div className="w-1/2 pr-10 text-right">
          <h2 className="text-5xl font-bold mb-8 leading-tight">
            이미지마다{' '}
            <span className="bg-gradient-to-tr from-[#DA9EFF] to-[#0B41FF] text-transparent bg-clip-text">
              다른 
            </span>{' '}
            얼굴<br />
            <span className="bg-gradient-to-tr from-[#DA9EFF] to-[#0B41FF] text-transparent bg-clip-text">
              균일한 
            </span>{' '}
            사진같은 퀄리티<br />
          </h2>
          <p className="text-[#999999] mb-8">
            에헤모는 같은 얼굴이 2번 생성되지 않습니다.<br />
            매 요청 랜덤한 시드를 사용해 다른 얼굴을 생성하면서도,<br />
            주머니에서 휴대폰을 꺼내 촬영한듯한 리얼함을 유지합니다. 
          </p>
          <Link to="/generated-images" className="bg-white text-black px-6 py-3 rounded-full flex items-center inline-flex">
            생성된 이미지들 <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>

        {/* Image Section (now on the right) */}
        <div className="flex justify-start items-center w-1/2 pl-4">
          <div className="relative w-4/5">  {/* 이미지 컨테이너 */}
            <img 
              src={image_realistic}
              alt="배경 이미지" 
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;