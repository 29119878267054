import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const FirstSection = () => {

  // 이미지 URL들을 배열로 저장 (새로운 URL로 교체)
  const images = [
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_1.JPEG',
    'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/wave/wave_1.JPEG',
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#0E0E11] text-white p-8 w-full">
      <div className="max-w-7xl mx-auto w-full">
        <div className="pt-20">
          <h1 className="text-6xl font-bold text-center mb-6 leading-[4rem]">
            원하는 헤어 디자인<br />
            <span className="bg-gradient-to-tr from-[#DA9EFF] to-[#0B41FF] text-transparent bg-clip-text">
              고퀄리티 헤어 모델 이미지
            </span>
            를<br />
            지금 바로 생성.
          </h1>
        </div>
      
        <div className="flex justify-center space-x-4 mb-8">
          <Link to="/generated-images" className="bg-white text-black px-6 py-3 rounded-full flex items-center">
            생성된 이미지들 <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
          <a 
            href="https://ehemo.basicfive.co.kr/" 
            target="_blank" 
            rel="noreferrer" 
            className="bg-white text-black px-6 py-3 rounded-full flex items-center"
          >
            앱 설치하기 <ArrowRight className="ml-2 h-4 w-4" />
          </a>
        </div>

        <div className="flex justify-center mb-2">
          {images.slice(0, 3).map((img, index) => (
            <div key={index} className="mx-[4px]">
              <img 
                src={img} 
                alt={`Generated hair model ${index + 1}`} 
                className="w-[300px] h-[300px] object-cover rounded-md" 
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          {images.slice(3).map((img, index) => (
            <div key={index + 3} className="mx-[4px]">
              <img 
                src={img} 
                alt={`Generated hair model ${index + 4}`} 
                className="w-[300px] h-[300px] object-cover rounded-md" 
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FirstSection;