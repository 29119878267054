import React, { useState, useEffect } from 'react';

const ImageCard = ({ image, onClick, aspectRatio }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative cursor-pointer w-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      style={{ aspectRatio: aspectRatio }}
    >
      <img 
        src={image.image_url} 
        alt={`${image.hairstyle_name}`}
        className="w-full h-full object-cover rounded-lg"
      />
      {isHovered && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start p-4 rounded-lg">
          <div className="text-white text-left">
            <p>#{image.hairstyle_name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const Modal = ({ image, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div className="max-w-4xl max-h-4xl" onClick={e => e.stopPropagation()}>
        <img 
          src={image.image_url} 
          alt={`${image.hairstyle_name}`}
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};

// 이미지 비율을 패턴에 따라 설정하는 함수
const assignAspectRatios = (images) => {
  return images.map((image, index) => {
    // 패턴에 따라 비율 결정 (홀수 인덱스는 1:1, 짝수 인덱스는 3:4)
    const aspectRatio = index % 2 === 0 ? '3/4' : '1/1';
    return {
      ...image,
      aspectRatio
    };
  });
};

// 이미지를 4개의 열로 분배하는 함수
const distributeImagesIntoColumns = (images) => {
  // 4개의 열 배열 초기화
  const columns = [[], [], [], []];
  
  // 이미지를 열에 분배
  images.forEach((image, index) => {
    // 순차적으로 열에 할당
    const columnIndex = index % 4;
    columns[columnIndex].push(image);
  });
  
  return columns;
};

const GeneratedImages = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageColumns, setImageColumns] = useState([[], [], [], []]);

  // 기본 이미지 데이터 배열
  const baseImages = [
    {
      id: 1,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_1.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 2,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_2.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 3,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_3.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 4,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_4.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 5,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_5.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 6,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_6.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 7,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_7.JPEG',
      hairstyle_name: '그레이스펌'
    },
    {
      id: 8,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_1.JPEG',
      hairstyle_name: '히피펌'
    },
    {
      id: 9,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_2.JPEG',
      hairstyle_name: '히피펌'
    },
    {
      id: 10,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_3.JPEG',
      hairstyle_name: '히피펌'
    },
    {
      id: 11,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_4.JPEG',
      hairstyle_name: '히피펌'
    },
    {
      id: 12,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_1.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 13,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_2.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 14,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_3.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 15,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_4.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 16,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_5.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 17,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_6.JPEG',
      hairstyle_name: '허쉬컷'
    },
    {
      id: 18,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_1.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 19,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_2.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 20,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_3.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 21,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_4.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 22,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_5.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 23,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_6.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 24,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_7.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 25,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_8.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 26,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_9.JPEG',
      hairstyle_name: '젤리펌'
    },
    {
      id: 27,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_1.JPEG',
      hairstyle_name: '레이어드컷'
    },
    {
      id: 28,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_2.JPEG',
      hairstyle_name: '레이어드컷'
    },
    {
      id: 29,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_3.JPEG',
      hairstyle_name: '레이어드컷'
    },
    {
      id: 30,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_4.JPEG',
      hairstyle_name: '레이어드컷'
    },
    {
      id: 31,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_5.JPEG',
      hairstyle_name: '레이어드컷'
    },
    {
      id: 32,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_1.JPEG',
      hairstyle_name: '태슬컷'
    },
    {
      id: 33,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_2.JPEG',
      hairstyle_name: '태슬컷'
    },
    {
      id: 34,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_3.JPEG',
      hairstyle_name: '태슬컷'
    },
    {
      id: 35,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_4.JPEG',
      hairstyle_name: '태슬컷'
    },
    {
      id: 36,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_5.JPEG',
      hairstyle_name: '태슬컷'
    },
    {
      id: 37,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/wave/wave_1.JPEG',
      hairstyle_name: '물결펌'
    },
    {
      id: 38,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/wave/wave_2.JPEG',
      hairstyle_name: '물결펌'
    },
    {
      id: 39,
      image_url: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/wave/wave_3.JPEG',
      hairstyle_name: '물결펌'
    }
  ];

  // 컴포넌트 마운트 시 이미지 비율 설정 및 열 분배
  useEffect(() => {
    const imagesWithAspectRatio = assignAspectRatios(baseImages);
    const columns = distributeImagesIntoColumns(imagesWithAspectRatio);
    setImageColumns(columns);
  }, []);

  return (
    <div className="flex">
      <div className="w-64 flex-shrink-0"></div> 
      <div className="flex-grow px-8 py-8 overflow-hidden">
        <h1 className="text-3xl font-bold mb-6">생성된 이미지</h1>
        <div className="grid grid-cols-4 gap-4 w-full">
          {imageColumns.map((column, colIndex) => (
            <div key={colIndex} className="flex flex-col gap-4">
              {column.map((image) => (
                <ImageCard 
                  key={image.id} 
                  image={image} 
                  onClick={() => setSelectedImage(image)}
                  aspectRatio={image.aspectRatio}
                />
              ))}
            </div>
          ))}
        </div>
        {selectedImage && (
          <Modal 
            image={selectedImage} 
            onClose={() => setSelectedImage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default GeneratedImages;