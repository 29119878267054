import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const hairStyles = [
  {
    id: 1,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/layered/layered_1.JPEG',
    title: '레이어드컷',
    description: '층이 있는 커트로 볼륨감과 움직임을 더해주는 스타일'
  },
  {
    id: 2,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hippy/hippy_1.JPEG',
    title: '히피펌',
    description: '부스스한 느낌에 자유분방하고 사랑스러운 느낌을 낼 수 있는 스타일'
  },
  {
    id: 3,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/grace/grace_1.JPEG',
    title: '그레이스펌',
    description: '우아하고 세련된 느낌의 자연스러운 웨이브 스타일'
  },
  {
    id: 4,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/tassle/tassle_1.JPEG',
    title: '태슬컷',
    description: '층 없이 깔끔한 라인으로 간결하고 세련된 연출이 가능한 단발 커트'
  }
];

const moreHairStyles = [
  {
    id: 5,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/wave/wave_1.JPEG',
    title: '물결펌',
    description: '자연스러운 웨이브로 여성스럽고 부드러운 느낌을 연출하는 스타일'
  },
  {
    id: 6,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/jelly/jelly_1.JPEG',
    title: '젤리펌',
    description: '탄력있는 컬이 특징인 볼륨감 있는 스타일'
  },
  {
    id: 7,
    image: 'https://basicfive-public.s3.ap-northeast-2.amazonaws.com/landing/hair_model_images/hush/hush_1.JPEG',
    title: '허쉬컷',
    description: '중단발 길이에서 층이 길게 커트돼있는 차분한 느낌의 스타일'
  }
];

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const imageWidth = 400; // 이미지 너비 (픽셀)
  const imageGap = 10; // 이미지 간 간격 (픽셀)

  const nextSlide = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      const initialOffset = imageWidth / 2 + imageGap / 2;
      const offset = currentIndex * (imageWidth + imageGap);
      carouselRef.current.style.transform = `translateX(calc(-${initialOffset}px - ${offset}px))`;
    }
  }, [currentIndex]);

  return (
    <div className="flex flex-col items-center justify-between w-full mb-16">
      <div className="relative w-full overflow-hidden">
        <div 
          ref={carouselRef}
          className="flex transition-all duration-300 ease-in-out"
          style={{
            marginLeft: '50%',
            transform: `translateX(calc(-${imageWidth / 2 + imageGap / 2}px))`,
          }}
        >
          {images.map((image, index) => (
            <div 
              key={image.id} 
              className="flex-shrink-0" 
              style={{ 
                width: `${imageWidth}px`, 
                marginRight: `${imageGap}px`
              }}
            >
              <img
                src={image.image}
                alt={image.title}
                className="w-full h-auto object-cover"
                style={{ aspectRatio: '1 / 1' }}
              />
            </div>
          ))}
        </div>
        <GradientOverlay />
        <CarouselButton direction="left" onClick={prevSlide} disabled={currentIndex === 0} />
        <CarouselButton direction="right" onClick={nextSlide} disabled={currentIndex === images.length - 1} />
      </div>
      <div className="flex justify-center mt-4 mb-4">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-[8px] h-[8px] rounded-full mx-[0.2rem] ${
              index === currentIndex ? 'bg-white' : 'bg-[#666666]'
            }`}
          />
        ))}
      </div>
      <div className="text-center">
        <h3 className="text-2xl font-semibold text-white mb-[0.1rem]">
          {images[currentIndex].title}
        </h3>
        <p className="text-[#999999]">
          {images[currentIndex].description}
        </p>
      </div>
    </div>
  );
};

const GradientOverlay = () => (
  <>
    <div className="absolute top-0 left-0 h-full w-[30%]" style={{
      background: 'linear-gradient(to right, #0E0E11 0%, rgba(14,14,17,0.7) 70%, rgba(14,14,17,0) 100%)'
    }}></div>
    <div className="absolute top-0 right-0 h-full w-[30%]" style={{
      background: 'linear-gradient(to left, #0E0E11 0%, rgba(14,14,17,0.7) 70%, rgba(14,14,17,0) 100%)'
    }}></div>
  </>
);

const CarouselButton = ({ direction, onClick, disabled }) => (
  <button
    onClick={onClick}
    className={`absolute ${direction}-0 top-1/2 transform -translate-y-1/2 z-20 bg-white rounded-full p-2 shadow-lg ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={disabled}
  >
    {direction === 'left' ? <ChevronLeft className="w-6 h-6 text-gray-800" /> : <ChevronRight className="w-6 h-6 text-gray-800" />}
  </button>
);

const HairStyleCarousel = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-[#0E0E11] text-white py-16 px-8 w-full">
      <div className="w-full max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-white mb-2">
            다양한 헤어스타일 제공
          </h1>
          <h2 className="text-xl text-[#999999]">
            다양한 헤어스타일 중에서 원하는 디자인을 골라보세요
          </h2>
        </div>
        <ImageCarousel images={hairStyles} />
        <ImageCarousel images={moreHairStyles} />
        <div className="flex justify-center mt-8 space-x-4">
          <Link to="/generated-images" className="bg-white text-black px-6 py-3 rounded-full inline-flex items-center">
            생성된 이미지들 <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
          <a 
            href="https://ehemo.basicfive.co.kr/" 
            target="_blank" 
            rel="noreferrer" 
            className="bg-white text-black px-6 py-3 rounded-full inline-flex items-center"
          >
            앱 설치하기 <ArrowRight className="ml-2 h-4 w-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HairStyleCarousel;